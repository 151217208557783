@import "init/variable";
@import "init/mixins";

/* slider layout */
.lof-slidecontent {
  position: relative;
  overflow: hidden;
  //border: #F4F4F4 solid 1px;

  .preload {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    text-align: center;
    background: #FFF
  }

  .preload div {
    height: 100%;
    width: 100%;
    background: transparent url(../img/lof/load-indicator.gif) no-repeat scroll 50% 50%;
  }

  .slider-container {
    height: 360px;
    @include mobile-width {
      height: 180px;
    }
  }

  .sliders-wrapper {
    position: relative;
    height: 100%;
    width: 900px;
    z-index: 3;
    overflow: hidden;
  }

  ul.sliders-wrap-inner {
    overflow: hidden;
    background: transparent url(../img/lof/load-indicator.gif) no-repeat scroll 50% 50%;
    padding: 0;
    margin: 0;
    position: absolute;
  }

  ul.sliders-wrap-inner li {
    overflow: hidden;
    padding: 0;
    margin: 0;
    float: left;
    position: relative;
  }

  .lof-opacity li {
    position: absolute;
    top: 0;
    left: 0;
    float: inherit;
  }

  .navigator-content {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9;
    height: 360px;
    width: 110px;
    overflow: hidden;
    color: #FFF
  }

  .navigator-wrapper {
    position: relative;
    z-index: 10;
    height: 180px;
    width: 120px;
    overflow: hidden;
    color: #FFF;
    float: left;
  }

  ul.navigator-wrap-inner {
    top: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
  }

  ul.navigator-wrap-inner li {
    cursor: pointer;
    list-style: none;
    padding: 0;
    margin-left: 0;
    overflow: hidden;
    float: left;
    display: block;
    text-align: center;
  }

  ul.sliders-wrap-inner li img {
    padding: 0;
  }

  .slider-description a.readmore {
    color: #58B1EA;
    font-size: 95%;
  }

  .slider-description a {
    color: #FFF;
  }

  .slider-description {
    z-index: 100;
    position: absolute;
    bottom: 66px;
    left: 0;
    width: 350px;
    background: url(../img/lof/bg_trans.png);
    height: 100px;
    padding: 10px;
    color: #FFF;
  }

  .slider-description h4 {
    font-size: 14px;
    margin: 10px 0;
    padding: 0;
  }

  .slider-description .slider-meta a {
    margin: 0;
    background: #C01F25;
    font-size: 75%;
    padding: 2px 3px;
    font-family: "Trebuchet MS", Trebuchet, Arial, Verdana, sans-serif;
    text-transform: uppercase;
    text-decoration: none
  }

  .item-meta a:hover {
    text-decoration: underline;
  }

  .item-meta i {
    font-size: 70%;
  }

  .navigator-content {
    @include mobile-width {
      display: none;
    }

    /* item navigator */
    ul.navigator-wrap-inner li.active {
      //background: #FEEEE7;
      //color: #FFF
    }

    ul.navigator-wrap-inner li > div {
      background: #fff7f9;
      opacity: 0.7;
      color: #FFF;
      height: 100%;
      position: relative;
      margin-left: 15px;
      padding-left: 15px;
      border-top: 1px solid #E1E1E1;
      text-align: left
    }

    ul.navigator-wrap-inner li.active div {
      background: #FEEEE7;
      color: #FFF;
      opacity: 1;
    }

    ul.navigator-wrap-inner li img {
      height: 60px;
      width: 60px;
      margin: 10px 15px 10px 0;
      float: left;
      padding: 3px;
      border: #C5C5C5 solid 1px;
    }

    ul.navigator-wrap-inner li.active img,
    ul.navigator-wrap-inner li:hover img {
      border: #6C8E5C solid 1px;
    }
  }

  .button-next,
  .button-previous {
    display: block;
    width: 40px;
    color: #FFF;
    cursor: pointer;
    position: absolute;
    height: 100%;
    z-index: 40;
    top: 0;
    text-indent: -999px;

  }

  .button-next {
    right: 0;
    background: url(../img/lof/right-arrow.png) no-repeat right center;
    opacity: 0.7;
  }

  .button-previous {
    left: 0;
    background: url(../img/lof/left-arrow.png) no-repeat left center;
    opacity: 0.7;
  }

  .button-control {
    position: absolute;
    top: 10px;
    right: 48%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    background: url(../img/lof/transparent_bg.png) repeat;
  }

  .button-control span {
    display: block;
    width: 100%;
    height: 100%;
  }

  .action-start span {
    background: url(../img/lof/play.png) no-repeat center center;
  }

  .action-stop span {
    background: url(../img/lof/pause.png) no-repeat center center;
  }
}