// メディアクエリ
@mixin max-screen($break-point) {
  @media screen and (max-width: $break-point) {
    @content;
  }
}
@mixin min-screen($break-point) {
  @media screen and (min-width: $break-point) {
    @content;
  }
}

@mixin screen($break-point-min, $break-point-max) {
  @media screen and (min-width: $break-point-min) and (max-width: $break-point-max) {
    @content;
  }
}

@mixin tablet-width () {
  @include min-screen($breakpoint-tablet) {
    @content
  };
}

@mixin mobile-width () {
  @include max-screen($breakpoint-mobile) {
    @content
  };
}

// clearfix用
@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: block;
  }
}
